import React from 'react'
import BOOKING_LINK from '../../../js/BOOKING_LINK'

const StyleCutBlowDry = () => {
  return (
    <div id="cuts" className="position-relative">
      <div className="container-fluid full-width-background-image-container">
        <div className="row h-100 background-colour-beige-1">
          <div className="col-md-6 order-md-2 background-image  background-cut"></div>
          <div className="col-md-6 order-md-1"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <section className="col-md-6 order-md-2">
            <div className="spacer-background-image"></div>
          </section>

          <section className="col-md-6 order-md-1 p-5">
            <h2>Style Cut and Blowdry</h2>
            <h4>
              I strive for beautiful hair that you can achieve at home. You will
              walk out ready to take on the world!
            </h4>
            <p>
              Hair Consult and Analysis- I want to create your ultimate style,
              come prepared with photos of styles you love (or show me what you
              don’t like) and we can make sure we are on the same page right
              from the beginning. (Pintrest or Instagram are great for
              inspiration)
            </p>
            <p>
              Basin- Recline back in our beautiful massage chair while I hand
              select the perfect shampoo and conditioner to suit your individual
              hair needs. You could even treat yourself to a specialised
              treatment for that extra touch of love and make your hair sing!!
            </p>
            <p>
              Massage- The ultimate experience with my signature head massage to
              completely relax and rejuvenate.
            </p>
            <p>
              Style Cut- Whatever style we are going to achieve, I create our
              shape first with wet hair, then your individual style blooms when
              I dry and see how I can perfect your style to sit flawlessly with
              various cutting techniques.
            </p>
            <p>
              Relax- Sit back while I create your perfect style and enjoy your
              favourite drink and treat, with great tunes and conversation.
            </p>
            <p>
              Finish- I create those finishing touches to give you the perfect
              style you can wear anywhere!
            </p>
            <a
              href={BOOKING_LINK}
              rel="noreferrer"
              target="_blank"
              className="links"
            >
              Book A Cut Style Blowdry
            </a>
          </section>
        </div>
      </div>
    </div>
  )
}

export default StyleCutBlowDry
